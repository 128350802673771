
import { defineComponent, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form } from "vee-validate";
import roadMapApi from "@/core/services/RoadMap";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import buttonHandle from "@/shared.js";

export default defineComponent({
    name: "roadmap",
    components: { Form },
    data: function () {
        if (Object.keys(this.$route.query).length === 0) {
            return {
                action: {
                    id: 0,
                    label: "",
                    collaborators: "",
                    deadline: "",
                    category: 0,
                    status: "TODO",
                    commentary: "",
                    impact: "",
                },
                roadmaps: [],
                categories: [],
                editing: false,
            };
        } else {
            return {
                action: {
                    id: this.$route.query.id,
                    label: this.$route.query.label,
                    collaborators: this.$route.query.collaborators,
                    deadline: this.$route.query.deadline,
                    category: this.$route.query.category,
                    status: this.$route.query.status,
                    commentary: this.$route.query.commentary,
                    impact: this.$route.query.impact,
                },
                roadmaps: [],
                categories: [],
                editing: true,
            };
        }
    },
    methods: {
        initActionAdd() {
            this.action = {
                id: 0,
                label: "",
                collaborators: "",
                deadline: "",
                category: 0,
                status: "TODO",
                commentary: "",
                impact: "",
            };
        },
        initActionEdit(action) {
            this.action.label = action.label;
            this.action.collaborators = action.collaborators;
            this.action.deadline = action.deadline;
            this.action.commentary = action.commentary;
            this.action.category = action.category.pk;
            this.action.status = action.status;
            this.action.id = action.pk;
        },
        addAction() {
            if (this.validateForm()) {
                buttonHandle.handleWaitingButton(this.submitButton);
                roadMapApi
                    .addAction(this.action)
                    .then(() => {
                        buttonHandle.handleValidatedButton(this.submitButton);
                        setTimeout(() => {
                            location.href = "/roadmap";
                        }, 500);
                    })
                    .catch(() => {
                        this.displayErrorMsg("Une erreur est survenue lors de l'ajout de l'action.");
                    });
            }
        },
        updateAction() {
            if (this.validateForm()) {
                buttonHandle.handleWaitingButton(this.submitButton);
                roadMapApi
                    .updateAction(this.action.id, this.action)
                    .then(() => {
                        buttonHandle.handleValidatedButton(this.submitButton);
                        setTimeout(() => {
                            location.href = "/roadmap";
                        }, 500);
                    })
                    .catch(() => {
                        this.displayErrorMsg("Une erreur est survenue lors de la mise à jour de l'action.");
                    });
            }
        },
        validateForm() {
            if (this.action.label === "") {
                this.displayErrorMsg(this.t("pages.roadmap.labelValidator"));
                return false;
            } else if (this.action.category === 0) {
                this.displayErrorMsg(this.t("pages.roadmap.themeValidator"));
                return false;
            } else if (this.action.deadline === "") {
                this.displayErrorMsg(this.t("pages.roadmap.deadlineValidator"));
                return false;
            } else if (this.action.impact === "") {
                this.displayErrorMsg(this.t("pages.roadmap.impactValidator"));
                return false;
            } else {
                return true;
            }
        },
        getRoadMapCategories() {
            roadMapApi
                .getRoadMapCategories()
                .then((response) => {
                    this.categories = response.data["roadmapscategories"];
                })
                .catch(() => {
                    this.displayErrorMsg("Une erreur est survenue lors de la récupération des catégories de plans d'action.");
                });
        },
        displayErrorMsg(msg: string) {
            Swal.fire({
                text: msg,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Fermer",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                },
            });
        },
    },
    setup() {
        const { t } = useI18n();
        const submitButton = ref<HTMLElement | null>(null);
        setCurrentPageBreadcrumbs(t("pages.roadmap.addRoadmap"), [
            {
                link: "/roadmap",
                label: t("pages.roadmap.title"),
            },
        ]);
        return { t, submitButton };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.roadmap.addRoadmap"), [
                {
                    link: "/roadmap",
                    label: this.t("pages.roadmap.title"),
                },
            ]);
        },
    },
    created() {
        this.getRoadMapCategories();
    },
});
