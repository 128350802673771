<template>
    <div class="row">
        <div class="col-12 mb-3">
            <div class="card mb-5 mb-xl-10">
                <div class="card-header border-0 cursor-pointer">
                    <!--begin::Card title-->
                    <div class="card-title m-0">
                        <h3 v-if="!editing" class="fw-bolder m-0">{{ $t("pages.roadmap.addRoadmap") }}</h3>
                        <h3 v-else class="fw-bolder m-0">{{ $t("pages.roadmap.updateRoadmap") }}</h3>
                    </div>
                    <!--end::Card title-->
                </div>
                <Form id="kt_add_action_form" class="form" novalidate="novalidate">
                    <div class="card-body border-top p-9">
                        <div class="row mb-6">
                            <label class="col-lg-4 col-form-label required fw-bold fs-6">{{ $t("pages.roadmap.yourAction") }}</label>
                            <div class="col-lg-8">
                                <div class="row">
                                    <div class="col-lg-12 fv-row">
                                        <textarea
                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                            :placeholder="$t('pages.roadmap.yourAction')"
                                            name="action"
                                            v-model="this.action.label"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-6">
                            <label class="col-lg-4 col-form-label required fw-bold fs-6">{{ $t("pages.roadmap.themes") }}</label>
                            <div class="col-lg-8">
                                <div class="row">
                                    <div class="col-lg-12 fv-row">
                                        <select v-model="this.action.category" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                            <option selected value="" disabled>{{ $t("pages.driveManagement.documents.chooseOption") }}</option>
                                            <option v-for="category in this.categories" v-bind:key="category.pk" :value="category.pk">
                                                {{ category.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-6">
                            <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t("pages.roadmap.collaborators") }}</label>
                            <div class="col-lg-8">
                                <div class="row">
                                    <div class="col-lg-12 fv-row">
                                        <textarea
                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                            :placeholder="$t('pages.roadmap.collaborators')"
                                            name="collaborators"
                                            v-model="this.action.collaborators"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-6">
                            <label class="col-lg-4 col-form-label required fw-bold fs-6">{{ $t("pages.roadmap.deadline") }}</label>
                            <div class="col-lg-8">
                                <div class="row">
                                    <div class="col-lg-12 fv-row">
                                        <input
                                            type="date"
                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                            placeholder="Date d'écheance"
                                            v-model="this.action.deadline"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-6">
                            <label class="col-lg-4 col-form-label required fw-bold fs-6">{{ $t("pages.roadmap.impact") }}</label>
                            <div class="col-lg-8">
                                <div class="row">
                                    <div class="col-lg-12 fv-row">
                                        <select v-model="this.action.impact" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                            <option selected disabled value>{{ $t("pages.driveManagement.documents.chooseOption") }}</option>
                                            <option value="1">{{ $t("pages.roadmap.lowImpact") }}</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">{{ $t("pages.roadmap.highImpact") }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-6">
                            <label class="col-lg-4 col-form-label required fw-bold fs-6">{{ $t("pages.roadmap.status") }}</label>
                            <div class="col-lg-8">
                                <div class="row">
                                    <div class="col-lg-12 fv-row">
                                        <select v-model="this.action.status" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0">
                                            <option value="TODO">{{ $t("pages.roadmap.todo") }}</option>
                                            <option value="INPR">{{ $t("pages.roadmap.inProgress") }}</option>
                                            <option value="DONE">{{ $t("pages.roadmap.done") }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-6">
                            <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t("pages.roadmap.commentaries") }}</label>
                            <div class="col-lg-8">
                                <div class="row">
                                    <div class="col-lg-12 fv-row">
                                        <textarea
                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                            :placeholder="$t('pages.roadmap.commentaries')"
                                            name="action"
                                            v-model="this.action.commentary"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
                <div class="card-footer d-flex justify-content-end py-6 px-9">
                    <button v-if="!editing" type="submit" @click="addAction" id="kt_account_profile_details_submit" ref="submitButton" class="btn btn-primary">
                        <span class="indicator-label"> {{ $t("general.add") }} </span>
                        <span class="indicator-progress">
                            {{ $t("general.pleaseWait") }}
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                        <span class="indicator-validate">
                            {{ $t("general.done") }}
                            <span class="fas fa-check f-5 ms-3"></span>
                        </span>
                    </button>

                    <button v-else type="submit" @click="updateAction" id="kt_account_profile_details_submit" ref="submitButton" class="btn btn-primary">
                        <span class="indicator-label"> {{ $t("general.update") }} </span>
                        <span class="indicator-progress">
                            {{ $t("general.pleaseWait") }}
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                        <span class="indicator-validate">
                            {{ $t("general.done") }}
                            <span class="fas fa-check f-5 ms-3"></span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form } from "vee-validate";
import roadMapApi from "@/core/services/RoadMap";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import buttonHandle from "@/shared.js";

export default defineComponent({
    name: "roadmap",
    components: { Form },
    data: function () {
        if (Object.keys(this.$route.query).length === 0) {
            return {
                action: {
                    id: 0,
                    label: "",
                    collaborators: "",
                    deadline: "",
                    category: 0,
                    status: "TODO",
                    commentary: "",
                    impact: "",
                },
                roadmaps: [],
                categories: [],
                editing: false,
            };
        } else {
            return {
                action: {
                    id: this.$route.query.id,
                    label: this.$route.query.label,
                    collaborators: this.$route.query.collaborators,
                    deadline: this.$route.query.deadline,
                    category: this.$route.query.category,
                    status: this.$route.query.status,
                    commentary: this.$route.query.commentary,
                    impact: this.$route.query.impact,
                },
                roadmaps: [],
                categories: [],
                editing: true,
            };
        }
    },
    methods: {
        initActionAdd() {
            this.action = {
                id: 0,
                label: "",
                collaborators: "",
                deadline: "",
                category: 0,
                status: "TODO",
                commentary: "",
                impact: "",
            };
        },
        initActionEdit(action) {
            this.action.label = action.label;
            this.action.collaborators = action.collaborators;
            this.action.deadline = action.deadline;
            this.action.commentary = action.commentary;
            this.action.category = action.category.pk;
            this.action.status = action.status;
            this.action.id = action.pk;
        },
        addAction() {
            if (this.validateForm()) {
                buttonHandle.handleWaitingButton(this.submitButton);
                roadMapApi
                    .addAction(this.action)
                    .then(() => {
                        buttonHandle.handleValidatedButton(this.submitButton);
                        setTimeout(() => {
                            location.href = "/roadmap";
                        }, 500);
                    })
                    .catch(() => {
                        this.displayErrorMsg("Une erreur est survenue lors de l'ajout de l'action.");
                    });
            }
        },
        updateAction() {
            if (this.validateForm()) {
                buttonHandle.handleWaitingButton(this.submitButton);
                roadMapApi
                    .updateAction(this.action.id, this.action)
                    .then(() => {
                        buttonHandle.handleValidatedButton(this.submitButton);
                        setTimeout(() => {
                            location.href = "/roadmap";
                        }, 500);
                    })
                    .catch(() => {
                        this.displayErrorMsg("Une erreur est survenue lors de la mise à jour de l'action.");
                    });
            }
        },
        validateForm() {
            if (this.action.label === "") {
                this.displayErrorMsg(this.t("pages.roadmap.labelValidator"));
                return false;
            } else if (this.action.category === 0) {
                this.displayErrorMsg(this.t("pages.roadmap.themeValidator"));
                return false;
            } else if (this.action.deadline === "") {
                this.displayErrorMsg(this.t("pages.roadmap.deadlineValidator"));
                return false;
            } else if (this.action.impact === "") {
                this.displayErrorMsg(this.t("pages.roadmap.impactValidator"));
                return false;
            } else {
                return true;
            }
        },
        getRoadMapCategories() {
            roadMapApi
                .getRoadMapCategories()
                .then((response) => {
                    this.categories = response.data["roadmapscategories"];
                })
                .catch(() => {
                    this.displayErrorMsg("Une erreur est survenue lors de la récupération des catégories de plans d'action.");
                });
        },
        displayErrorMsg(msg: string) {
            Swal.fire({
                text: msg,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Fermer",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                },
            });
        },
    },
    setup() {
        const { t } = useI18n();
        const submitButton = ref<HTMLElement | null>(null);
        setCurrentPageBreadcrumbs(t("pages.roadmap.addRoadmap"), [
            {
                link: "/roadmap",
                label: t("pages.roadmap.title"),
            },
        ]);
        return { t, submitButton };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.roadmap.addRoadmap"), [
                {
                    link: "/roadmap",
                    label: this.t("pages.roadmap.title"),
                },
            ]);
        },
    },
    created() {
        this.getRoadMapCategories();
    },
});
</script>
